export const adimurDescription =
    <div>
        <p>La Región de Murcia ha demostrado ser una de las regiones más resilientes y dinámicas económicamente.
            Sus directivos, defienden y luchan por los intereses y objetivos de nuestros empresarios, y lo hacen con su buen hacer y esfuerzo.
            Con su compromiso y aprendizaje permanentes. Con su lealtad.</p>
        <p>
            Como directivos, es importante estar preparados para los cambios y ser flexibles en la toma de decisiones.
            La capacidad de adaptación y anticipación a éstos, serán claves para el éxito en un entorno económico incierto.
            Además, es importante tener una comprensión sólida de los riesgos y oportunidades presentes en el mercado y
            estar dispuestos a tomar decisiones audaces cuando sea necesario.
        </p>
        <p>
            En ADIMUR desde hace ya 10 años, pensamos que es importante saber qué piensan nuestros directivos,
            como motor de la economía regional. Por ello, pedimos tu colaboración para llevar a cabo este importante estudio,
            completando el formulario en el enlace que os dejamos a continuación.
        </p>
        <p>
            Los resultados de dicha investigación, pondrán de manifiesto, qué piensan nuestros directivos,
            su visión de las empresas y la economía.
            Estudio que se presentará a la prensa, a finales de enero y
            será una muestra más de la voz que tiene el directivo en la Región de Murcia a través de ADIMUR.
        </p>
        <p>
            ¡Gracias por tu colaboración!
        </p>
    </div>