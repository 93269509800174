import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Space,
    message, Popconfirm, Select
} from "antd";
import PhocusTable from "../../components/PhocusTable/PhocusTable";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {IField} from "../../interfaces/IField";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteSurvey} from "./actions";
import {ISurvey} from "../../interfaces/ISurvey";
import {IForm} from "../../interfaces/IForm";
import {getDateRender} from "../../components/AntdUtils";


export default function FormList(){

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [list, setList] = useState<IForm[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<ISurvey | undefined>(undefined);

    useEffect(() => {
        loadFormList();

        return () => {
        };
    }, []);

    function loadFormList () {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"survey-processor/getForms");
        restClient.doGet((result: []) => {
            result && setList(result)
        });

    }

    const columns : IField<IForm>[]=
        [
            {
                title: 'Código',
                dataIndex: 'formid',
                key: 'formid',
                show:false,
                sorter: (a, b) => a.formid > b.formid ? 1 : -1,
            },
            {
                title: 'Fecha',
                dataIndex: 'createdat',
                key: 'createdat',
                show:true,
                sorter: (a, b) => a.createdat > b.createdat ? 1 : -1,
                render: getDateRender()
            },
            {
                title: 'Usuario',
                dataIndex: 'useraccount',
                key: 'useraccount',
                show:true,
                sorter: (a, b) => {
                    if (a?.useraccount != null && b?.useraccount != null) {
                        return a.useraccount > b.useraccount ? 1 : -1;
                    }
                    // Manejo adicional si alguno es null
                    return a?.useraccount == null ? 1 : -1;
                },
                render: (value, record) => {
                    return (<p>{record.useraccount?.email}</p>)

                }
            },
            {
                title: 'Encuesta',
                dataIndex: 'surveyid',
                key: 'surveyid',
                show:true,
                sorter: (a, b) => a.surveyid > b.surveyid ? 1 : -1,
                render: (value, record) => {
                    return (<p>{record.surveyid.surveyTitle}</p>)

                }
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {
                    return (
                        <Space size="middle">
                            <a onClick={() => navigate("/blocks/"+record.surveyid)
                            }>Detalles </a>
                        </Space>
                    );
                },
            }
        ];

    return (
        <>
            <PhocusTable columns={columns} dataSource={list} />
        </>
    );
}