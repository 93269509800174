import styles from './Header.module.css';
import {Menu,  Button, Space, Dropdown} from "antd";
import React from 'react';

import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {logout} from "../../Controller/redux/auth/actions";
import phocusLogo from "../../assets/phocus-logo.png";
import {
    BorderBottomOutlined,
    DollarCircleFilled,
    DownOutlined,
    ExclamationOutlined,
    FlagOutlined,
    FundProjectionScreenOutlined,
    HomeFilled,
    LineChartOutlined,
    LoginOutlined,
    MenuOutlined,
    ProfileOutlined,
    QuestionCircleOutlined,
    RightOutlined,
    UserAddOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Header} from "antd/es/layout/layout";

export default function AdminHeader(){
    const dispatch = useDispatch();
    //@ts-ignore
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();

    function onLogOut() {
        //@ts-ignore
        dispatch(logout())
        navigate("/login")
    }

    const menu = <Menu mode="inline" theme="light" style={{ height: '100%', borderRight: 0 }}>
        <Menu.Item key="1" icon={<HomeFilled />}>
            <Link to="/home">Inicio</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<RightOutlined />}>
            <Link to="/surveys">Encuestas</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<RightOutlined />}>
            <Link to="/forms">Formularios</Link>
        </Menu.Item>
        <Menu.Item key="9" icon={<UserAddOutlined />}>
            <Link to="/login">Login</Link>
        </Menu.Item>
        {auth && auth.logged &&
            <Menu.Item key="8" icon={<LoginOutlined />}>
                <a onClick={onLogOut}>Cerrar sesión</a>
            </Menu.Item>
        }
    </Menu>;

    return (
        <Header style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", backgroundColor: "whitesmoke", paddingLeft: 12, paddingRight:12 }}>
            <img className={styles.logo} src={phocusLogo} onClick={() => navigate("/home")}/>
            <Dropdown overlay={menu}>
                <Button>
                    <Space>
                        <MenuOutlined />
                    </Space>
                </Button>
            </Dropdown>
        </Header>
    );
}