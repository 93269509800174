import styles from './Footer.module.css';
import React from 'react';
import {useNavigate} from "react-router-dom";
import phocusLogo from "../../assets/phocus-logo.png";
import {Footer} from "antd/es/layout/layout";

export default function FooterWithoutMenu(){
    const navigate = useNavigate();

    return (
        <Footer
            style={{
                display: 'flex',
                alignItems: "center",
                justifyContent: "right",
                backgroundColor: "whitesmoke",
                paddingLeft: 12,
                paddingRight: 12,
                position: "fixed", // Fija el footer
                bottom: 0,        // Lo coloca en la parte inferior
                width: "100%",    // Asegura que ocupe todo el ancho de la pantalla
                zIndex: 1000      // Opcional, para asegurar que esté por encima de otros elementos
            }}
        >
            <img className={styles.logo} src={phocusLogo} onClick={() => window.open("https://www.phocus.es/", "_blank")} // Abrir en una nueva pestaña
            />
        </Footer>
    );
}